import React, { Component } from 'react';
import { connect } from 'react-redux';
import {imagePath} from '../actions/ImagePath';
import { APP_LOAD } from '../constants/actionTypes';
import Home from '../Components/Home';
import Success from '../Components/Success';
import Cancel from '../Components/Cancel';
import Directsuccess from '../Components/Directsuccess';
import Directcancel from '../Components/Directcancel';
import CardCancel from '../Components/CardCancel';
import Loader from '../Components/Loader';
import GBLVAR from '../GlobalVariables/globalvaribles';
import { Route, Switch,BrowserRouter as Router } from 'react-router-dom';

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
    setTimeout(() =>  
      this.props.onLoad()
    , 2000);
  }
  render() {
    return(
      <div>
        {this.props.state.loading === true ?
          <div className="loading_image_wrapper">
            <Loader className="loading_image_inner" image="logo.gif"/>
          </div>
        :
        window.location.pathname != GBLVAR.BASE_URL+GBLVAR.PAGE_NAME.Success && window.location.pathname != GBLVAR.BASE_URL+GBLVAR.PAGE_NAME.Cancel && window.location.pathname != GBLVAR.BASE_URL+GBLVAR.PAGE_NAME.Decline ?
        <Switch>
          <Route exact={true} path={GBLVAR.PAGE_NAME.Root+'/:id'} component = {Home} />
          <Route exact={true} path={GBLVAR.PAGE_NAME.Home+'/:id'} component = {Home} />
          <Route exact={true} path={GBLVAR.PAGE_NAME.Success} component = {Success} />
          <Route exact={true} path={GBLVAR.PAGE_NAME.Cancel} component = {Cancel} />
          <Route exact={true} path={GBLVAR.PAGE_NAME.Directsuccess+'/:id'} component = {Directsuccess} />
          <Route exact={true} path={GBLVAR.PAGE_NAME.Directcancel+'/:id'} component = {Directcancel} />   
          <Route exact={true} path={GBLVAR.PAGE_NAME.CardCancel} component = {CardCancel} /> 
        </Switch>
         :
         <div className="wrapper-payment">          
            <Switch>
              <Route exact={true} path={GBLVAR.PAGE_NAME.Success} component = {Success} />
              <Route exact={true} path={GBLVAR.PAGE_NAME.Cancel} component = {Cancel} />
            </Switch>
         </div>         
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    state : state.AppReducer
  }
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch({ type: APP_LOAD })
});


export default connect(mapStateToProps, mapDispatchToProps)(App);

