import React, { Component } from 'react';
import { connect } from 'react-redux';
import {imagePath} from '../actions/ImagePath';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import axios from 'axios';

class Cancel extends Component {
    constructor (props) {
        super(props);
        var app_details = window.parent.app_details;
        var mechant_details = window.parent.getMerchantDetails;
        var headers = window.parent.headers;
        var transaction_number = window.parent.transaction_id;
        this.state = {
            app_details:app_details,
            mechant_details:mechant_details,
            headers:headers,
            transaction_number:transaction_number,
            payment_transaction_id:"",
            reference_id:"",
            transaction_no:""
        }
        this.paymentComplete = this.paymentComplete.bind(this);
    }
    componentDidMount() {
        var _this=this;
        var urlParams = new URLSearchParams(window.location.search);
        _this.setState({reference_id:urlParams.get('reference_id_top'),payment_transaction_id:urlParams.get('payment_transaction_id')}, () => {
            _this.paymentComplete();
        }); 
    }
    paymentComplete(){
        var data = {
            'site_plan_id':this.state.app_details.id,
            'site_plan_duration':this.state.app_details.duration,
            'site_plan_type':this.state.app_details.plan_type,
            'plan_type':this.state.app_details.plan_type,
            'reference_id':this.state.reference_id,
            'transaction_id':window.parent.transaction_id,
            'payment_transaction_id':this.state.payment_transaction_id,
            'merchant_id':this.state.mechant_details.user_no,
            'payment_gateway':"paytabpci"
        }
        axios({
            method:'POST',
            url:GBLVAR.API_URL+GBLVAR.API_LIST.COMPLETE_TRANSACTION,
            headers:this.state.headers,
            data:data
        })
		.then((response)=>{
            /* setTimeout(function(){
                window.top.location.href = GBLVAR.RELOAD_URL;
            },1000); */
        })
        .catch((error)=> {
            if (error.response.status==422) {
                this.setState({cancelMessage:error.response.data.message});
            }
        });
    }
    render() {
        return (
            <div className="col-xs-12 no-padding">
                <div className="log_wr_over">
                    <div className="center_wrap_overs">
                        <img className="successPayimg" src={imagePath('./cancel.svg')} />
                        <h1> Payment Cancelled</h1>
                        {/*<div className="transactionPay">Reason : {this.state.cancelMessage}</div>  */}
                        <div className="transactionPay">Note : If the amount is deducted from your card. It will be refunded immediately. Please contact your bank for support.</div>                     
                    </div>
                </div>
            </div>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		state : ""
	}
};
          
export default connect(mapStateToProps)(Cancel);  