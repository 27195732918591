import axios from 'axios';
import GBLVAR from '../GlobalVariables/globalvaribles';


class ApiRequests {  
  static returnCurrentCountry() {
    return axios.get(GBLVAR.GET_COUNTRY)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
  }
  static getValues(API_URL,URLTYPE) {    
    return axios({method:'GET',url:API_URL + URLTYPE})
    .then(function (response) {
      if (response.status === 200){
        return response.data;
      }  
      if (response.status === 422){
        return response.message
      }     
    })
    .catch(function (error) {
      if(error.response.status === 422){
        return error.response.status;
      }
      else{
        return error.response.data;
      }      
    });
  }
  static postValues(API_URL,URLTYPE,params,header) {    
    return axios({method:'POST',url:API_URL + URLTYPE,headers:header,data: params})
    .then(function (response) {
      if (response.status === 200){
        return response.data;
      }  
      if (response.status === 422){
        return response.message
      }     
    })
    .catch(function (error) {
      if(error.response.status === 422){
        return error.response.status;
      }
      else{
        return error.response.data;
      }      
    });
  }
}
  export default ApiRequests;  