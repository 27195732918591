module.exports = {
    //USER_URL:"http://192.168.1.66:3000",
    USER_URL:"https://subscription.foloosi.com",
    //USER_URL:"https://subscription.roamsoft.co",
    BASE_URL : "",
    PAGE_NAME:{
    	Root : '/pay',
        Home : '/pay',
        Success : '/success',
        Cancel : '/cancel',
        Decline : '/decline',
        Directsuccess:'/directsuccess',
        Directcancel:'/directcancel',
        CardCancel:'/card-cancel'
    },
    //API_URL:'https://flstrans.roamsoft.co/v1/',    
    API_URL:'https://flstrans.foloosi.com/v1/',
    //API_REQUEST_URL:'https://roamsoft.co:8443/v1/',
    API_REQUEST_URL:'https://foloosi.com/api/v1/',
    GET_COUNTRY :'https://pro.ip-api.com/json?key=RMV9nkZYs1rNPZQ',
    API_LIST: {
        CHECK_TOKEN : 'user/get-plan-detail',
        GUEST_SIGNUP : 'api/guest-signup',
        INITIATE_TRANSACTION : 'plan/payment/initialize',
        CHARGE_REQUEST:'payment/charge-request',
        COMPLETE_TRANSACTION:'plan/payment/complete',
        SENT_NOTIFICATION:'sent-notification',
    },
    //RELOAD_URL: 'https://www.roamsoft.co/merchant/plans/pricing',
    RELOAD_URL: 'https://www.foloosi.com/merchant/plans/pricing'
}    