import React, { Component } from 'react';
import { connect } from 'react-redux';

class Header extends Component {
    constructor (props) {
		super(props);
		this.state = {            
		}
    }
    componentDidMount(){
        //console.log("state",this.props.state.app_details)
    }
    render() {
        const getdetails = this.props.state.app_details;
        const getMerchantDetails = this.props.state.merchant;
        return (
            <div className="tvr-login-head">
                <div className="ler_powe">
                    <div className="create_namesw_new">Name : {getMerchantDetails.business_name}</div>
                    <div className="create_namesw_new">Email : {getMerchantDetails.email}</div>
                    <div className="create_namesw_new">Phone : {getMerchantDetails.phone_number}</div>
                    <div className="create_namesw">Plan Type : {getdetails.plan_name}</div>
                    <div className="payReffrom">Amount Payable</div>
                    <div className="amt_pais">{getdetails.currency} {getdetails.transaction_amount}</div> 
                </div>
            </div>	
        );
    }
}

const mapStateToProps = state => {
    return {
        state : state.AppReducer
    }
};  

export default connect(mapStateToProps)(Header);