import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET_DETAILS, CURRENT_COUNTRY, COUNTRY_DETAIL, CHANGE_VALUE, ERROR_CHANGE, UPDATE_HEADER, SET_ERROR } from '../constants/actionTypes';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import Header from './Header';
import Loader from './Loader';
import Footer from './Footer';
import { imagePath } from '../actions/ImagePath';
import PhoneCode from './PhoneCode';
import { StateList } from './StateList';
import { Countries } from './countries';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import { returnCountryInfo, getInfo, returnCodeInfo } from "../Services/Utils";
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import axios from 'axios';
const CryptoJS = window.CryptoJS;
var CryptoJSAesJson = {
	'stringify': function (cipherParams) {
		var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
		if (cipherParams.iv) j.iv = cipherParams.iv.toString()
		if (cipherParams.salt) j.s = cipherParams.salt.toString()
		return JSON.stringify(j).replace(/\s/g, '')
	},
	'parse': function (jsonStr) {
		var j = JSON.parse(jsonStr)
		var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
		if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
		if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
		return cipherParams
	}
}
var cardNumber = "";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				'reference_id': this.props.match.params.id
			},
			paymentTokenStatus: false,
			paymentToken: {},
		}
		this.defaultGuestDetails = this.defaultGuestDetails.bind(this);
		this.handleGuestEvent = this.handleGuestEvent.bind(this);
		this.handleKeyEvent = this.handleKeyEvent.bind(this);
		this.handlePaste = this.handlePaste.bind(this);
		this.validateEmail = this.validateEmail.bind(this);
		this.checkNameFormat = this.checkNameFormat.bind(this);
		this.onCreditCardTypeChanged = this.onCreditCardTypeChanged.bind(this);
		this.onCreditCardChange = this.onCreditCardChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.handleExpiryMonthYear = this.handleExpiryMonthYear.bind(this);
		this.handleOnCvvChange = this.handleOnCvvChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.postalCodeChange = this.postalCodeChange.bind(this);
		this.showErrors = this.showErrors.bind(this);
		this.setValidFields = this.setValidFields.bind(this);
		this.encryptMethod = this.encryptMethod.bind(this);
		this.setValidPostal = this.setValidPostal.bind(this);
		this.applePay = this.applePay.bind(this);
		this.fraudCheck = this.fraudCheck.bind(this);
	}
	applePay() {
		var _this = this;
		var send_detail = _this.props.state;
		var runningTotal = send_detail.app_details.transaction_amount;
		var paymentRequest = {
			currencyCode: 'AED',
			countryCode: 'AE',
			total: {
				label: 'FOLOOSI',
				amount: runningTotal
			},
			supportedNetworks: ['amex', 'masterCard', 'visa'],
			merchantCapabilities: ['supports3DS', 'supportsEMV', 'supportsCredit', 'supportsDebit']
		};
		var session = new window.ApplePaySession(1, paymentRequest);
		session.onvalidatemerchant = function (event) {
			//var promise = this.performValidation(event.validationURL);
			var promise = axios({
				method: 'post',
				url: GBLVAR.API_URL + 'applepay/request-token',
				data: {
					'validation_url': event.validationURL,
					'access_from': 'payment_link'
					//'access_from' : fintech/payment_link/api/product
				}
			}).then(function (response) {
				return response.data.data;
			})
				.catch(function (error) {
					console.log(error);
				});
			//console.log(promise);
			promise.then(function (merchantSession) {
				session.completeMerchantValidation(merchantSession);
			});
		}
		session.onpaymentmethodselected = function (event) {
			var newTotal = { type: 'final', label: 'Foloosi', amount: runningTotal };
			var newLineItems = [];
			session.completePaymentMethodSelection(newTotal, newLineItems);
		}
		session.onpaymentauthorized = function (event) {
			//var promise = this.sendPaymentToken(event.payment.token);
			var paymentToken = event.payment.token;
			var promise = new Promise(function (resolve, reject) {
				_this.setState({ paymentToken: paymentToken });
				return resolve(true);
			});
			promise.then(function (success) {
				var status;
				if (success) {
					status = window.ApplePaySession.STATUS_SUCCESS;
					_this.setState({ paymentTokenStatus: true }, () => {
						_this.paynow();
					});
				} else {
					status = window.ApplePaySession.STATUS_FAILURE;
				}
				session.completePayment(status);
			});
		}
		session.oncancel = function (event) {
			//console.log(event);
			_this.props.history.push(GBLVAR.PAGE_NAME.CardCancel);
		}
		session.begin();
	}
	encryptMethod(text) {
		let encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), process.env.REACT_APP_KEY, { format: CryptoJSAesJson }).toString();
		return encrypted;
	}
	showErrors() {
		var state = this.props.state;
		var cardname = state.validCardName === true ? 'Name, ' : '';
		var carnumber = state.cardNumberValid === true || state.cardNumberValid === undefined ? 'Card number, ' : '';
		var cardexpiry = state.expiry_common === true || state.expiry_common === undefined ? 'Expiry, ' : '';
		var cardcvv = state.cardCvvValid === true || state.cardCvvValid === undefined ? 'CVV, ' : '';
		var cardcountry = state.selectedCountry.value !== "" ? '' : 'Country, ';
		var cardaddress = state.valid_billing_address === true || state.valid_billing_address === undefined ? 'Address, ' : '';
		var cardcity = state.valid_city === true || state.valid_city === undefined ? 'City, ' : '';
		var cardstate = state.valid_state === true || state.valid_state === undefined ? 'State, ' : '';
		var cardpostalcode = state.valid_postal_code === true || state.valid_postal_code === undefined ? 'Postal Code ' : '';
		this.props.setCountryDetail({ Error_Message: `${'Please fill ' + cardname + carnumber + cardexpiry + cardcvv + cardcountry + cardaddress + cardcity + cardstate + cardpostalcode + 'fields below'}` });
	}
	paynow() {
		var headers = {
			'is_encrypted': true
		}
		this.props.updateCredential(headers).then(() => {
			var send_detail = this.props.state;
			this.props.setCountryDetail({ loderRun: true, showCard: "" });
			const [cc_first_name, cc_last_name] = this.props.state.name.split(' ');
			
			var encrypt = {
				'order_id': window.transaction_id,
				'amount': send_detail.app_details.transaction_amount,
				'first_name': cc_first_name,
				'last_name': cc_last_name !== '' && cc_last_name !== undefined ? cc_last_name : cc_first_name,
				'card_number': send_detail.cardNumber,
				'card_exp': send_detail.cardExpiryYear + send_detail.cardExpiryMonth,
				'card_cvv': send_detail.cardCvv,
				'billto_country': send_detail.selectedCountry.value,
				'billto_address': send_detail.billing_address,
				'billto_state': send_detail.state,
				'billto_city': send_detail.city,
				'billto_postal_code': send_detail.postal_code == '0' ? '000000' : send_detail.postal_code,
				'success_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?reference_id_top=' + send_detail.app_details.reference_no,
				'cancel_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel + '?reference_id_top=' + send_detail.app_details.reference_no,
				'payment_gateway': "paytabpci",
				'merchant_id': send_detail.merchant.user_no
			}
			var data = {
				order: this.encryptMethod(encrypt)
			}
			dataservice.postValues(GBLVAR.API_REQUEST_URL, GBLVAR.API_LIST.CHARGE_REQUEST, data, this.props.state.headers)
				.then((response) => {
					if (response !== 422 && response.data === undefined) {
						this.props.setCountryDetail({ Error_Message: `${'Please the fields below'}`, showCard: 'card_screen', loderRun: false });
					} else if (response === 422) {
						this.props.history.push(GBLVAR.PAGE_NAME.CardCancel);
					} else {
						this.props.setCountryDetail({ loderRun: false, payment_response: response.data }).then(() => {
							var send_detail = this.props.state;
							if (send_detail.payment_response.result === "3d Redirect") {
								this.props.setCountryDetail({ showCard: "3d Redirect" }).then(() => {
									//document.getElementById("foloosiPluginApiiframe").setAttribute('src', send_detail.payment_response.redirect_url);
									var paymentFrameDoc = document.getElementById("foloosiPluginApiiframe").contentDocument || document.getElementById("foloosiPluginApiiframe").contentWindow.document;
									paymentFrameDoc.open();
									paymentFrameDoc.write("<html><body></body></html>");
									paymentFrameDoc.close();
									var form = paymentFrameDoc.createElement("form");
									form.setAttribute("method", "POST");
									form.setAttribute("action", send_detail.payment_response.redirect_url);
									form.setAttribute("enctype", "application/x-www-form-urlencoded");
									paymentFrameDoc.body.appendChild(form);
									form.submit();
								})
							}
							else if (send_detail.payment_response.result === "Approved") {
								if (send_detail.payment_response.response_code == '100') {
									this.props.history.push(GBLVAR.PAGE_NAME.Directsuccess + '/' + send_detail.app_details.reference_no + '?reference_id_top=' + send_detail.app_details.reference_no + '&payment_transaction_id=' + send_detail.payment_response.transaction_id);
								}
								else {
									this.props.history.push(GBLVAR.PAGE_NAME.Directcancel + '/' + send_detail.app_details.reference_no + '?reference_id_top=' + send_detail.app_details.reference_no + '&payment_transaction_id=' + send_detail.payment_response.transaction_id);
								}
							}
							else {
								this.props.history.push(GBLVAR.PAGE_NAME.Directcancel + '/' + send_detail.app_details.reference_no + '?reference_id_top=' + send_detail.app_details.reference_no + '&payment_transaction_id=' + send_detail.payment_response.transaction_id);
							}
						})
					}
				})
		})
	}
	postalCodeChange(e) {
		var postalRawValue = e.target.rawValue;
		this.setValidPostal(postalRawValue);
	}
	setValidPostal(value) {
		var postalRawValue = value;
		if (this.props.state.selectedCountry.value === "USA") {
			if (postalRawValue.length === 9) {
				const code = postalRawValue.slice(0, 5) + "-" + postalRawValue.slice(-4)
				this.props.setCountryDetail({ postal_code: code }).then(() => {
					var regEx = /^\d{5}-\d{4}$/;
					if (!regEx.test(this.props.state.postal_code)) {
						this.props.setCountryDetail({ valid_postal_code: true });
					}
					else {
						this.props.setCountryDetail({ valid_postal_code: false });
					}
				})
			}
			else {
				this.props.setCountryDetail({ valid_postal_code: true });
			}
		}
		else {
			if (postalRawValue.length === 6) {
				const code = postalRawValue.slice(0, 3) + " " + postalRawValue.slice(-3)
				this.props.setCountryDetail({ postal_code: code }).then(() => {
					var regEx = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;
					if (!regEx.test(this.props.state.postal_code)) {
						this.props.setCountryDetail({ valid_postal_code: true });
					}
					else {
						this.props.setCountryDetail({ valid_postal_code: false });
					}
				})
			}
			else {
				this.props.setCountryDetail({ valid_postal_code: true });
			}
		}
	}
	handleChange(event) {
		var error = {};
		var name = event.target.name;
		error[name] = "";
		this.props.setCountryDetail({ [name]: event.target.value }).then(() => {
			this.props.setError(error).then(() => {
				this.setValidFields(name);
			})
		})
	}
	setValidFields(name) {
		var error = {};
		if (name === "name") {
			if (this.props.state.name === "") {
				this.props.setCountryDetail({ validCardName: true });
			} else {
				if (!this.checkNameFormat(this.props.state.name, "name")) {
					error.name = 'Please Enter Valid Name, Example: Omar Ali (Only English letters allowed)';
					this.props.setError(error);
					this.props.setCountryDetail({ validCardName: true });
				} else {
					this.props.setCountryDetail({ validCardName: false });
				}
			}
		}
		if (name === "billing_address") {
			if (this.props.state.billing_address.length >= 1 && this.props.state.billing_address.length <= 100) {
				if (!this.checkNameFormat(this.props.state.billing_address, "address")) {
					error.billing_address = 'Address must be contain A-Z, a-z, 0-9, space only';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_billing_address: true });
				}
				else {
					this.props.setCountryDetail({ valid_billing_address: false });
				}
			} else {
				this.props.setCountryDetail({ valid_billing_address: true });
			}
		}
		if (name === "city") {
			if (this.props.state.city !== "") {
				if (!this.checkNameFormat(this.props.state.city, "check")) {
					error.city = 'Only English letters and spaces are allowed';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_city: true });
				}
				else {
					this.props.setCountryDetail({ valid_city: false });
				}
			} else {
				this.props.setCountryDetail({ valid_city: true });
			}
		}
		if (name === "state") {
			if (this.props.state.state !== "") {
				if (!this.checkNameFormat(this.props.state.state, "check")) {
					error.state = 'Only English letters and spaces are allowed';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_state: true });
				}
				else {
					this.props.setCountryDetail({ valid_state: false });
				}
			} else {
				this.props.setCountryDetail({ valid_state: true });
			}
		}
		if (name === "postal_code") {
			if (this.props.state.postal_code === "") {
				this.props.setCountryDetail({ valid_postal_code: true });
			} else {
				if (!this.checkNameFormat(this.props.state.postal_code, "postal_code")) {
					error.postal_code = 'Postal Codee must be contain A-Z, a-z, 0-9, space only';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_postal_code: true });
				}
				else {
					this.props.setCountryDetail({ valid_postal_code: false });
				}
			}
		}
	}
	handleOnCvvChange(event) {
		this.props.setCountryDetail({ cardCvv: event.target.rawValue }).then(() => {
			if (this.props.state.cardCvv.length < 3) {
				this.props.setCountryDetail({ cardCvvValid: true });
			}
			else {
				this.props.setCountryDetail({ cardCvvValid: false });
			}
		});
	}
	onDateChange(event) {
		var dateRawValue = event.target.rawValue;
		if (dateRawValue.length === 4) {
			this.props.setCountryDetail({ cardExpiryMonth: dateRawValue.slice(0, 2), cardExpiryYear: dateRawValue.slice(-2) })
		}
		if (dateRawValue.length < 4) {
			this.props.setCountryDetail({ cardExpiryMonth: "", cardExpiryYear: "", expiry_common: true })
		}
	}
	handleExpiryMonthYear(event) {
		var now = new Date();
		var currentYear = now.getFullYear().toString().substr(-2);
		var currentMonth = now.getMonth() + 1;
		currentMonth = parseInt(currentMonth);
		currentYear = parseInt(currentYear);
		if (this.props.state.cardExpiryYear !== "" && this.props.state.cardExpiryMonth !== "") {
			var expiryDateYear = parseInt(this.props.state.cardExpiryYear);
			var expiryDateMonth = parseInt(this.props.state.cardExpiryMonth);
			if (currentYear === expiryDateYear) {
				if (expiryDateMonth < currentMonth) {
					this.props.setCountryDetail({ expiry_common: true });
				} else {
					this.props.setCountryDetail({ expiry_common: false });
				}
			} else {
				if (expiryDateYear < currentYear) {
					this.props.setCountryDetail({ expiry_common: true });
				} else {
					this.props.setCountryDetail({ expiry_common: false });
				}
			}
		}
	}
	onCreditCardChange(event) {
		cardNumber = event.target.rawValue;
		this.props.setCountryDetail({ cardNumber: event.target.rawValue }).then(() => {
			var rupayRegex = /^(508[5-9]|60(80(0|)[^0]|8[1-4]|8500|698[5-9]|699|7[^9]|79[0-7]|798[0-4])|65(2(1[5-9]|[2-9])|30|31[0-4])|817[2-9]|81[89]|820[01])/;
			var discoverRegex = /^6(?:018\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})$/;
			var card_value = this.props.state.cardNumber.replace(/\s/g, '');
			if (rupayRegex.test(card_value) === true) {
				this.props.setCountryDetail({ creditCardType: 'rupay', cardNumberValid: false });
			}
			else if (discoverRegex.test(card_value) === true) {
				this.props.setCountryDetail({ creditCardType: 'discover', cardNumberValid: false });
			}
		})
	}
	onCreditCardTypeChanged(type) {
		this.props.setCountryDetail({ creditCardType: type }).then(() => {
			if (this.props.state.creditCardType == 'unknown' || this.props.state.creditCardType == 'uatp') {
				this.props.setCountryDetail({ cardNumberValid: true });
			}
			else {
				this.props.setCountryDetail({ cardNumberValid: false });
			}
		});
	}
	checkNameFormat(name, key) {
		// var regName =  key === "name" ? /^[A-Za-z\s]+$/ : key === "address" ? /^[^\s]+[A-Za-z0-9\_ ]+$/ : key === "check" ? /^[^\s]+[A-Za-z ]+$/ : /^\S.*/; 
		// if(regName.test(name)) {                     
		// 	return name; 
		// }else{
		// 	return false;  
		// }
		var regName = key === "name" ? /^[a-zA-Z][a-zA-Z ]*$/ : key === "address" ? /^[a-zA-Z0-9][a-zA-Z_0-9 ]*$/ :
			key === "check" ? /^[a-zA-Z][a-zA-Z ]*$/ : /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/;
		if (regName.test(name)) {
			return name;
		} else {
			return false;
		}
	}
	validateEmail(email) {
		var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
		return re.test(email);
	}
	handleGuestEvent(name) {
		var error = {};
		error[name] = '';
		this.props.errorChange(error);
	}
	handleKeyEvent(e) {
		var theEvent = e || window.event;
		if (theEvent.type === 'paste') {
			key = e.clipboardData.getData('text/plain');
		} else {
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
		}
		var regex = /[0-9]|\./;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	}
	handlePaste(e) {
		e.preventDefault();
	}
	defaultGuestDetails() {
		const valid = isValidPhoneNumber(this.props.state.app_details.link_mobile);
		const country_detail = parsePhoneNumber(this.props.state.app_details.link_mobile);
		if (valid) {
			var CountryInfo = returnCountryInfo("+" + country_detail.countryCallingCode);
			this.props.setCountryDetail(getInfo(CountryInfo, country_detail.nationalNumber));
		} else {
			if (country_detail !== undefined) {
				var CountryInfo = returnCountryInfo("+" + country_detail.countryCallingCode);
				this.props.setCountryDetail(getInfo(CountryInfo, country_detail.nationalNumber));
			} else {
				var CountryInfo = returnCodeInfo(this.props.state.current_country.countryCode);
				this.props.setCountryDetail(getInfo(CountryInfo, this.props.state.app_details.link_mobile));
			}
		}
	}
	fraudCheck(){
		var self = this;
		window.seon.config({
			session_id: `"${self.props.match.params.id}"`,
			audio_fingerprint: true,
			canvas_fingerprint: true,
			webgl_fingerprint: true,
			onSuccess: function(message) {
				console.log("success", message);
				window.seon.getBase64Session(function(data) {
					if (data) {
						//console.log("Session payload", data);
						self.setState({ payload : data })
					} else {
						console.log("Failed to retrieve session data.");
					}
				});
			},
			onError: function(message) {
				console.log("error", message);
			}
		});
	}
	componentDidMount() {
		this.fraudCheck();
		dataservice.getValues(GBLVAR.API_URL, GBLVAR.API_LIST.CHECK_TOKEN+'/'+this.state.data.reference_id)
		.then((response) => {
			this.props.getDetails(response).then(() => {
				window.app_details = this.props.state.app_details;
				window.getMerchantDetails = this.props.state.merchant;
				const getdetails = this.props.state.app_details;
				const getMerchantDetails = this.props.state.merchant;
				var data = {
					'email': getMerchantDetails.email,
					'name': getMerchantDetails.business_name,
					'phone_number': getMerchantDetails.phone_number,
					'phone_code': getMerchantDetails.country_code
				}
				dataservice.postValues(GBLVAR.API_REQUEST_URL, GBLVAR.API_LIST.GUEST_SIGNUP, data)
				.then((response) => {
					var credentials = response.data;
					var headers = {
						'Authorization': 'Bearer ' + credentials.authentication,
						'auth_token': credentials.auth_token,
						'platform': credentials.platform
					}
					this.props.updateCredential(headers).then(() => {
						window.headers = this.props.state.headers;

						var headers = {}
						var plandata = {
							plan_id:getdetails.id,
							duration:getdetails.duration,
							transaction_amount:getdetails.transaction_amount,
							merchant_id:getMerchantDetails.user_no,
							plan_type:getdetails.plan_type
						}
						this.props.updateCredential(headers).then(() => {
							window.headers = this.props.state.headers;
							dataservice.postValues(GBLVAR.API_URL, GBLVAR.API_LIST.INITIATE_TRANSACTION, plandata, this.props.state.headers)
							.then((response) => {
								var initiate_trans = response.data;
								window.transaction_id = initiate_trans.transaction_id;	
								setTimeout(() => {
									var initiate_trans = {
										loderRun: false,
										showCard: "card_screen",
										validCardName: false,
										viewscreen:"Home"
									}
									this.props.setCountryDetail(initiate_trans);
								}, 1000);
							})
						}) 
					})
				})
			})
		})
	}
	render() {
		const getdetails = this.props.state.app_details;
		const getMerchantDetails = this.props.state.merchant;
		const customStyles = {
			control: () => ({
				border: '0', minHeight: 'auto', boxShadow: 'none', float: 'left', width: '100%'
			}),
			indicatorsContainer: () => ({
				padding: '0', float: 'left', width: '30px'
			}),
			valueContainer: () => ({
				padding: '0 10px 0 0', float: 'left', width: 'calc(100% - 30px)', position: 'relative'
			}),
			indicatorSeparator: () => ({
				marginRight: '2px', backgroundColor: '#e1e1e1', width: '1px', float: 'left', height: '12px', marginTop: '5px'
			}),
			dropdownIndicator: () => ({
				padding: '0', float: 'left', width: '25px', height: '18px'
			}),
			input: () => ({
				margin: '0px', padding: '0px'
			})
		}
		const countryList = Countries.map(function (country, i) {
			return (
				{ label: country.name, value: country["3digit"] }
			)
		}, this);
		var StateNames = [];
		StateList.map(function (statename, i) {
			if (this.props.state.selectedCountry.label === statename.name) {
				return (
					StateNames = statename.states.map(function (list, j) {
						return (
							{ label: list.name, value: list.abbreviation }
						)
					}, this)
				)
			}
		}, this);
		return (
			<div>
				<div className="wrapper-login">
					<div className="modalOuterWraping">
						<div className="tvr-main-wrapper">
							<div className="tvr-login-wrap">
								<Header />								
								{
									this.props.state.viewscreen === "" ?
										<div className="col-xs-12 no-padding  text-center">
											<img src={imagePath("./loader.gif")} alt="" />
										</div>
										:
										this.props.state.viewscreen == "Home" &&
											<div className="col-xs-12 no-padding wrapper_content">
												<div className="col-xs-12 no-padding payment_wrapper">
													<div className="frame-open foloosiPluginApiiframeWrapper" >
														{this.props.state.loderRun === true &&
															<Loader className="loaderIcon loaderIconWrap" image="loader.gif" />
														}
														{this.props.state.showCard === "card_screen" ?
																<div className="pay_popup_section">
																	<div className="pay_popup_section_form">
																		<div className="pay_popup_section_form_card" onKeyPress={e => this.props.setCountryDetail({ Error_Message: "" })}>
																			{
																				this.props.state.Error_Message &&
																				<div className="error_message_top">{this.props.state.Error_Message}</div>
																			}
																			<div className="pay_popup_section_field">
																				<label className="pay_popup_card_label"
																				>Name on Card</label>
																				<input
																					type="text"
																					name="name"
																					placeholder="Card Holder's Name"
																					className="pay_popup_card_input"
																					value={this.props.state.name}
																					onChange={e => { this.handleChange(e); }}
																					style={{ borderColor: this.props.state.validCardName ? 'red' : '#e1e1e1' }}
																					ref="nameinput"
																					tabIndex="5"
																				/>
																				{this.props.state.payment_error.name &&
																					<span
																						className="error_message_bottom_new">
																						{this.props.state.payment_error.name}
																					</span>}
																			</div>
																			<div className="pay_popup_section_field">
																				<label className="pay_popup_card_label"
																				>Enter Card No.</label>
																				<Cleave type="tel" style={{ borderColor: this.props.state.cardNumberValid ? 'red' : '#e1e1e1' }}
																					value={cardNumber} className="pay_popup_card_input" placeholder="Enter card number" options={{ creditCard: true, onCreditCardTypeChanged: this.onCreditCardTypeChanged }}
																					onChange={this.onCreditCardChange} tabIndex="1" />
																				<div className="pay_pop_card_img" style={{ backgroundImage: `url(${this.props.state.creditCardType == 'visa' && imagePath("./visacard.png") || this.props.state.creditCardType == 'rupay' && imagePath("./rupaycard.png") || this.props.state.creditCardType == 'master' || this.props.state.creditCardType == 'mastercard' && imagePath("./master.png") || this.props.state.creditCardType == 'diners' && imagePath("./diners.png") || this.props.state.creditCardType == 'amex' && imagePath("./amex.png") || this.props.state.creditCardType == 'discover' && imagePath("./discover.png")})` }}></div>
																			</div>
																			<div className="pay_popup_section_field no-margin">
																				<div className="pay_popup_section_field_expiry">
																					<label className="pay_popup_card_label"
																					>Expiry</label>
																					<Cleave tabIndex="2" placeholder="MM/YY" options={{ date: true, datePattern: ['m', 'y'] }}
																						onKeyUp={e => { this.handleExpiryMonthYear(e); }} ref="monthyearinput"
																						onChange={this.onDateChange} style={{ borderColor: this.props.state.expiry_common ? 'red' : '#e1e1e1' }} className="pay_popup_card_input" />
																				</div>
																				<div className="pay_popup_section_field_cvv plr-15">
																					<label className="pay_popup_card_label"
																					>Enter CVV</label>
																					{this.props.state.creditCardType == 'amex' ?
																						<Cleave style={{ borderColor: this.props.state.cardCvvValid ? 'red' : '#e1e1e1' }} className="pay_popup_card_input" type="password" placeholder="••••" maxLength="4" options={{ numericOnly: true }}
																							onChange={this.handleOnCvvChange} ref="cvvinput" name="cvvinput" tabIndex="4" />
																						:
																						<Cleave style={{ borderColor: this.props.state.cardCvvValid ? 'red' : '#e1e1e1' }} className="pay_popup_card_input" type="password" placeholder="•••" maxLength="3" options={{ numericOnly: true }}
																							onChange={this.handleOnCvvChange} ref="cvvinput" name="cvvinput" tabIndex="4" />
																					}
																				</div>
																				<div className="pay_popup_section_field_country">
																					<label className="pay_popup_card_label"
																					>Country</label>
																					<Select className="pay_popup_card_input pay_popup_card_country"
																						isSearchable
																						options={countryList}
																						value={this.props.state.selectedCountry}
																						onChange={e => this.props.setCountryDetail({
																							selectedCountry: e,
																							state: "", postal_code: ""
																						})}
																						maxMenuHeight={'170px'}
																						styles={customStyles}
																					/>
																				</div>
																				<div className="pay_popup_section_field no-margin">
																					<div className="pay_popup_section_field">
																						<label className="pay_popup_card_label"
																						>Billing Address</label>
																						<input type="text" name="billing_address" placeholder="Billing Address"
																							value={this.props.state.billing_address || ''}
																							onChange={e => this.handleChange(e)}
																							style={{ borderColor: this.props.state.valid_billing_address ? 'red' : '#e1e1e1' }} className="pay_popup_card_input" tabIndex="5" />
																						{this.props.state.payment_error.billing_address &&
																							<span
																								className="error_message_bottom_new">
																								{this.props.state.payment_error.billing_address}
																							</span>}
																					</div>
																				</div>
																			</div>
																			<div className="pay_popup_section_field no-margin">
																				<div className="pay_popup_section_field_city">
																					<label className="pay_popup_card_label"
																					>City</label>
																					<input type="text" name="city"
																						onChange={e => this.handleChange(e)}
																						value={this.props.state.city || ''} placeholder="Enter City"
																						style={{ borderColor: this.props.state.valid_city ? 'red' : '#e1e1e1' }} className="pay_popup_card_input" tabIndex="5" />
																					{this.props.state.payment_error.city &&
																						<span
																							className="error_message_bottom_new">
																							{this.props.state.payment_error.city}
																						</span>}
																				</div>
																				{
																					this.props.state.selectedCountry.value === "USA" || this.props.state.selectedCountry.value === "CAN" ?
																						<div className="pay_popup_section_field_state plr-15">
																							<label className="pay_popup_card_label"
																							>State</label>
																							<Select className={this.props.state.valid_state === true ? "pay_popup_card_input pay_popup_card_country pay_popup_card_country_error" : "pay_popup_card_input pay_popup_card_country"}
																								isSearchable
																								options={StateNames}
																								value={this.props.state.selectedState || ""}
																								onChange={e => this.props.setCountryDetail({ state: e.value, valid_state: false, selectedState: e })}
																								maxMenuHeight={'170px'}
																								styles={customStyles}
																								menuPlacement="top"
																							/>
																						</div>
																						:
																						<div className="pay_popup_section_field_state plr-15">
																							<label className="pay_popup_card_label"
																							>State</label>
																							<input type="text" name="state"
																								onChange={e => this.handleChange(e)}
																								value={this.props.state.state || ''} placeholder="Enter State" className="pay_popup_card_input"
																								style={{ borderColor: this.props.state.valid_state ? 'red' : '#e1e1e1' }}
																								tabIndex="5" />
																							{this.props.state.payment_error.state &&
																								<span
																									className="error_message_bottom_new">
																									{this.props.state.payment_error.state}
																								</span>}
																						</div>
																				}
																				<div className="pay_popup_section_field_city">
																					<label className="pay_popup_card_label"
																					>Postal Code</label>
																					{
																						this.props.state.selectedCountry.value === "USA" &&
																						<div>
																							<Cleave
																								options={
																									{
																										delimiter: '-',
																										blocks: [5, 4],
																										numericOnly: true
																									}
																								}
																								onChange={this.postalCodeChange}
																								placeholder="Eg. 12345-6789"
																								className="pay_popup_card_input"
																								style={{ borderColor: this.props.state.valid_postal_code ? 'red' : '#e1e1e1' }} />
																							<span data-error="postalcode" className="error_message_bottom"></span>
																						</div>
																					}
																					{
																						this.props.state.selectedCountry.value === "CAN" &&
																						<div>
																							<Cleave
																								options={
																									{
																										delimiter: ' ',
																										blocks: [3, 3],
																										uppercase: true
																									}
																								}
																								onChange={this.postalCodeChange}
																								placeholder="Eg. A1B 2C3"
																								className="pay_popup_card_input"
																								style={{ borderColor: this.props.state.valid_postal_code ? 'red' : '#e1e1e1' }} />
																							<span data-error="postalcode" className="error_message_bottom"></span>
																						</div>
																					}
																					{
																						this.props.state.selectedCountry.value === "CAN" || this.props.state.selectedCountry.value === "USA"
																							?
																							null
																							:
																							<>
																								<input type="text" name="postal_code" placeholder="Enter Postal Code"
																									value={this.props.state.postal_code || ''}
																									onChange={e => this.handleChange(e)}
																									style={{ borderColor: this.props.state.valid_postal_code ? 'red' : '#e1e1e1' }} className="pay_popup_card_input" tabIndex="5" />
																								{this.props.state.payment_error.postal_code &&
																									<span
																										className="error_message_bottom_new">
																										{this.props.state.payment_error.postal_code}
																									</span>}
																							</>
																					}
																				</div>
																			</div>
																			<div className="pay_popup_section_field no-margin">
																				{this.props.state.validCardName === false && this.props.state.cardNumberValid === false && this.props.state.expiry_common === false &&
																					this.props.state.cardCvvValid === false && this.props.state.valid_billing_address === false && this.props.state.valid_city === false &&
																					this.props.state.valid_state === false && this.props.state.valid_postal_code === false ?
																					<a className="pay_popup_submit" onClick={e => this.paynow('card')} >Pay Now</a>
																					:
																					<a className="pay_popup_submit_disable" onClick={e => this.showErrors()}>Pay Now</a>
																				}
																			</div>
																		</div>
																	</div>
																</div>
																:
																<>
																	{this.props.state.showCard === "3d Redirect" &&
																		<iframe id="foloosiPluginApiiframe" className="frame-open paytabs"></iframe>}
																</>
																}
															</div>
															
												</div>
											</div>
								}
							</div>
						</div>
						<Footer />
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		state: state.AppReducer
	}
};

const mapDispatchToProps = dispatch => ({
	getDetails: (response) => { dispatch({ type: GET_DETAILS, payload: response }); return Promise.resolve(); },
	currentcountry: (payload) => { dispatch({ type: CURRENT_COUNTRY, payload }); return Promise.resolve(); },
	setCountryDetail: (payload) => { dispatch({ type: COUNTRY_DETAIL, payload }); return Promise.resolve(); },
	handleChange: (name, value) => dispatch({ type: CHANGE_VALUE, payload: { name, value } }),
	errorChange: (payload) => dispatch({ type: ERROR_CHANGE, payload }),
	updateCredential: (payload) => { dispatch({ type: UPDATE_HEADER, payload }); return Promise.resolve(); },
	setError: (payload) => { dispatch({ type: SET_ERROR, payload }); return Promise.resolve(); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);    