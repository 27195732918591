import React, { Component } from 'react';
import {imagePath} from '../actions/ImagePath';

class Footer extends Component {
    render() {
        return (
            <div className="footer_wrapper">
                <div className="accepted_card_pay">
                    <div className="col_erc">	
                        <div className="right_wep">
                            <ul className="paycard_info ">
                                <li><img src={imagePath('./cards.png')} height="30" alt="" /></li>
                            </ul>
                            <ul className="paycard_info_bottom">
                                <li className="align_left"><img src={imagePath('./pci.png')} height="30" alt="" /></li>
                                <li className="align_right"><img src={imagePath('./footer-logo.png')} height="30" alt="" /></li>
                            </ul>
                        </div>	
                    </div>
                </div>
            </div>
        );
    }
}
          
export default Footer;