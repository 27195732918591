import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';

class Cardcancel extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        cancelMessage:"",
        paydetails:{}
      }
    }
    componentDidMount() {
        
    }
    render() {
        const getdetails = this.props.state.app_details;
        return (
            <div className="wrapper-login"> 
				<div className="modalOuterWraping">  
					<div className="tvr-main-wrapper">
						<div className="tvr-login-wrap">
							<Header />
                            <div className="col-xs-12 no-padding wrapper_content">
                                <div className="log_wr_over">
                                    <div className="center_wrap_overs">   
                                        <img className="successdirectimg" src={imagePath('./cancel.svg')} />
                                        <h1> Payment Cancelled</h1>
                                        <div className="transactionPay">Note : Your card not supported</div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</div>
                    <Footer />
				</div>
            </div>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		state : state.AppReducer
	}
};
          
export default connect(mapStateToProps)(Cardcancel);





