import React, { Component } from 'react';
import { Countries } from './countries';
import { connect } from 'react-redux';
import { CHANGE_VALUE,COUNTRY_DETAIL } from '../constants/actionTypes';
import { getInfo } from "../Services/Utils";

class PhoneCode extends Component {    
    constructor (props) {
        super(props);
        this.state = {
          countryList : []
        }
    }
    componentDidMount(){
        var _this = this;
        if(_this.props.showFirst){
            const filterCountry = Countries.filter(prod => prod.name !== _this.props.showFirst)
            const filter_obj = Countries.filter(prod => prod.name == _this.props.showFirst)
            _this.setState({
                countryList: _this.state.countryList.concat(filter_obj,filterCountry)
            })
        }
    }
    render() {
        const countryList = this.state.countryList.map(function(country,i) {
			return(
				<li key={i} value={country.code} className="country preferred active" data-dial-code={country.code} data-country-code={country.flag} onClick={(e) => {this.props.setCountryDetail(getInfo(country));this.props.handleChange("countryFlag",!this.props.state.countryFlag)}}>
                    <div className="flag-box">
                        <div className={"iti-flag "+country.flag}></div>
                    </div>
                    <span className="country-name">{country.name}</span>
                    <span className="dial-code">{country.code}</span>
                </li>
			)
		},this);
        return (
            <ul
            className="country-list"                
            >
                { countryList }
            </ul> 
        );
    }
}

const mapStateToProps = state => {
    return {
        state : state.AppReducer
    }
};
  
const mapDispatchToProps = dispatch => ({
    handleChange: (name,value) => dispatch({ type: CHANGE_VALUE, payload:{name,value} }),
    setCountryDetail: (payload) => dispatch({ type: COUNTRY_DETAIL, payload }),
});

export default connect(mapStateToProps,mapDispatchToProps)(PhoneCode);