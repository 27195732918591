import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import axios from 'axios';

class Directcancel extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        cancelMessage:"",
        paydetails:{},
        app_details:this.props.state.app_details,
        mechant_details:this.props.state.merchant
      }
      this.paymentComplete = this.paymentComplete.bind(this);
    }
    paymentComplete(){
        var data = {
            'site_plan_id':this.state.app_details.id,
            'site_plan_duration':this.state.app_details.duration,
            'site_plan_type':this.state.app_details.plan_type,
            'plan_type':this.state.app_details.plan_type,
            'reference_id':this.state.reference_id,
            'transaction_id':window.transaction_id,
            'payment_transaction_id':this.state.payment_transaction_id,
            'merchant_id':this.state.mechant_details.user_no,
            'payment_gateway':"paytabpci"
        }
        axios({
            method:'POST',
            url:GBLVAR.API_URL+GBLVAR.API_LIST.COMPLETE_TRANSACTION,
            headers:this.props.state.headers,
            data:data
        })
		.then((response)=>{
            /* setTimeout(function(){
                window.top.location.href = GBLVAR.RELOAD_URL;
            },1000); */
        })
        .catch((error)=> {
            if (error.response.status==422) {
                this.setState({cancelMessage:error.response.data.message});
            }
        });
    }
    componentDidMount() {
        var _this=this;
        var urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('reference_id_top')!==null && urlParams.get('payment_transaction_id')!==null){
            _this.setState({reference_id:urlParams.get('reference_id_top'),payment_transaction_id:urlParams.get('payment_transaction_id')}, () => {
                _this.paymentComplete();
            }); 
        }
    }
    render() {
        const getdetails = this.props.state.app_details;
        return (
            <div className="wrapper-login"> 
				<div className="modalOuterWraping">  
					<div className="tvr-main-wrapper">
						<div className="tvr-login-wrap">
							<Header />
                            <div className="col-xs-12 no-padding wrapper_content">
                                <div className="log_wr_over">
                                    <div className="center_wrap_overs">   
                                        <img className="successdirectimg" src={imagePath('./cancel.svg')} />
                                        <h1> Payment Cancelled</h1>
                                        <div className="transactionPay">Note : If the amount is deducted from your card. It will be refunded immediately. Please contact your bank for support.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</div>
                    <Footer />
				</div>
            </div>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		state : state.AppReducer
	}
};
          
export default connect(mapStateToProps)(Directcancel);
