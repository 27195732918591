import React, { Component } from 'react';
import {imagePath} from '../actions/ImagePath';

class Loader extends Component {
    componentDidMount(){
        //console.log("this.props",this.props)
    }
    render() {
        return (
            <div className={this.props.className}>   
                <img src={imagePath(`./${this.props.image}`)} alt=""/>
                <p className="loading_text_contFirst">Loading... </p>
                <p className="loading_text_cont"> Kindly DO NOT close, refresh or click back button.</p>
            </div>
        );
    }
}
          
export default Loader;