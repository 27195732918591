import {
  APP_LOAD,
  GET_DETAILS,
  CURRENT_COUNTRY,
  COUNTRY_DETAIL,
  CHANGE_VALUE,
  ERROR_CHANGE,
  UPDATE_HEADER,
  SET_ERROR
} from '../constants/actionTypes';

const defaultState = {
  loading: true,
  app_details:{},
  merchant:{},
  current_country:{},
  viewscreen:"",
  paymentframe:false,
  countryFlag:false,
  disableguestPay:false,
  errors:{},
  headers : {
    'access_for':'pay_link'
  },
  selectedCountry : {},
  payment_error:{}
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {        
        ...state,
        loading: false
      };  
    case GET_DETAILS:
      return {        
        ...state,
        app_details : action.payload.data,
        merchant : action.payload.data.merchant
      };  
    case CURRENT_COUNTRY:
      return {        
        ...state,
        current_country : action.payload
      }; 
    case COUNTRY_DETAIL:
      return {        
        ...state,...action.payload
      };
    case CHANGE_VALUE:
      return {        
        ...state,
        [action.payload.name] : action.payload.value
      }; 
    case ERROR_CHANGE:
      return {        
        ...state,
        errors : {
          ...state.errors,
          ...action.payload
        }
      }; 
    case UPDATE_HEADER:
      return {        
        ...state,
        headers :{
          ...state.headers,
          ...action.payload
        }
      }; 
    case SET_ERROR:
      return {        
        ...state,
        payment_error : {
          ...state.payment_error,
          ...action.payload
        }
      }; 
    default : return state;  
  }
}

