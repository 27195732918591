import { Countries } from '../Components/countries';

export function returnCountryInfo(code) {
  let coObj = Countries.findIndex(x => x.code === code);
  return Countries[coObj];
}

export function getInfo(detail,number) {
  var data = {
    country_code : detail.code,
    country_name : detail.name,
    country_flag : detail.flag,
    countryRegion : detail["2digit"]    
  }
  if(number !== undefined){
    data.ph_number = number;
  }
  return data;
}

export function returnCodeInfo(code) {
  let coObj = Countries.findIndex(x => x["2digit"] == code || x["3digit"] == code);
  return Countries[coObj];
}